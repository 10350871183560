import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { save, load } from "redux-localstorage-simple";
import { Provider } from "react-redux";
import { fetchProducts } from "./redux/actions/productActions";
import rootReducer from "./redux/reducers/rootReducer";
import products from "./data/products.json";
import App from "./App";
import "./assets/scss/style.scss";
import * as serviceWorker from "./serviceWorker";

import { composeWithDevTools } from "redux-devtools-extension";
import toast, { Toaster } from 'react-hot-toast';
import store from './store';
import {getToken} from "./redux/actions/kiotVietAuthoAction";

const initializeApp = async () => {
  // const reduxData = JSON.parse(localStorage.getItem('redux_localstorage_simple'));
  // var Today = new Date().getTime();
  // if (!reduxData || reduxData.kiotViet.token == null || reduxData.kiotViet.token == "" || new Date().getTime() > reduxData.kiotViet.expireDate) {
  //   console.log('Retake Token')
  //   await store.dispatch(getToken());
  // }
  ReactDOM.render(
    <Provider store={store}>
      <App />
      <Toaster position="top-right" />
    </Provider>,
    document.getElementById("root")
  );
  serviceWorker.unregister();
};

initializeApp();
