import {KIOTVIET_TOKEN} from "../actions/kiotVietAuthoAction";
const initState = {
  expireDate:null,
  token:""
};
const authReducer = (state=initState, action) => {

  if (action.type === KIOTVIET_TOKEN) {
      const now = new Date();
      return {
        expireDate: now.getTime() + (action.payload.expires_in-3600) * 1000,
        token:action.payload.access_token,
      };
  }
  return state;
};

export default authReducer;
