import http from '../configKiotViet/configKiotVietAPI'
import config from '../configKiotViet'
const API_URL = config.API;
class AuthService {
  login(email, password) {
    return http
      .post(API_URL + "AuthManagement/Login", { email, password })
      .then((response) => {
        return response;
      });
  }
  logout() {
  }
  register(data) {
    return http.post(API_URL + "AuthManagement/RegisterWithKiotViet", data).then((response) => {
      return response;
    });;
  }
}

export default new AuthService();