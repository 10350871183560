
import AuthService from "../../services/auth.kiotViet.service";
import toast from 'react-hot-toast';

export const KIOTVIET_TOKEN = "KIOTVIET_TOKEN";
  export const getToken = () => (dispatch) => {
    return AuthService.getToken().then(
      (response) => {
        dispatch({
          type: KIOTVIET_TOKEN,
          payload: response.data,
        });
        return Promise.resolve();
      }
    ).catch(
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.errors) ||
          error.message ||
          error.toString();
        
        toast.error(message, {
        });
        return Promise.reject();
      }
    );
  };
  
  