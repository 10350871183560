export const FETCH_CATEGOTY_PRODUCTS_SUCCESS = "FETCH_CATEGOTY_PRODUCTS_SUCCESS";

const fetchProductCategotysSuccess = categorys => ({
  type: FETCH_CATEGOTY_PRODUCTS_SUCCESS,
  payload: categorys
});

// fetch products
export const fetchProductsCategory = categorys => {
  return dispatch => {
    dispatch(fetchProductCategotysSuccess(categorys));
  };
};
