import toast from 'react-hot-toast';

export const ADD_TO_CART = "ADD_TO_CART";
export const DECREASE_QUANTITY = "DECREASE_QUANTITY";
export const DELETE_FROM_CART = "DELETE_FROM_CART";
export const DELETE_ALL_FROM_CART = "DELETE_ALL_FROM_CART";

//add to cart
export const addToCart = (
  item,
  addToast,
  quantityCount,
  selectedProductColor,
  selectedProductSize
) => {
  return dispatch => {
    if (addToast) {
      toast.success('Added To Cart', {});
    }
    var cartProductColor = item.variation.find(x=>x.color===selectedProductColor)
    var cartProductSize = cartProductColor.size.find(x=>x.name===selectedProductSize)
    debugger
    dispatch({
      type: ADD_TO_CART,
      payload: {
        ...item,
        quantity: quantityCount,
        selectedProductColor: selectedProductColor
          ? selectedProductColor
          : item.selectedProductColor
            ? item.selectedProductColor
            : null,
        selectedProductSize: selectedProductSize
          ? selectedProductSize
          : item.selectedProductSize
            ? item.selectedProductSize
            : null,
        cartProductId:cartProductSize.id,
        cartProductImage:cartProductColor?cartProductColor.image[0]:item.images[0],
      }
    });
  };
};
//decrease from cart
export const decreaseQuantity = (item, addToast) => {
  return dispatch => {
    if (addToast) {
      // toast('Item Decremented From Cart!',
      //   {
      //     icon: '⚠️',
      //     style: {
      //       background: '#ffc107',
      //       color: '#fff',
      //     },
      //   }
      // );
      toast.success('Item Decremented From Cart', {
        style: {
          background: '#ffc107',
          color: '#fff',
        },
      });
    }
    dispatch({ type: DECREASE_QUANTITY, payload: item });
  };
};
//delete from cart
export const deleteFromCart = (item, addToast) => {
  return dispatch => {
    if (addToast) {
      // toast('Removed From Cart!',
      //   {
      //     icon: '⚠️',
      //     style: {
      //       background: '#ffc107',
      //       color: '#fff',
      //     },
      //   }
      // );
      toast.success('Removed From Cart', {
        style: {
          background: '#ffc107',
          color: '#fff',
        },
      });
    }
    dispatch({ type: DELETE_FROM_CART, payload: item });
  };
};
//delete all from cart
export const deleteAllFromCart = addToast => {
  return dispatch => {
    if (addToast) {
      // addToast("Removed All From Cart", {
      //   appearance: "error",
      //   autoDismiss: true
      // });

      // toast('Removed All From Cart!',
      //   {
      //     icon: '⚠️',
      //     style: {
      //       background: '#ffc107',
      //       color: '#fff',
      //     },
      //   }
      // );
      toast.success('Removed All From Cart', {
        style: {
          background: '#ffc107',
          color: '#fff',
        },
      });
    }
    dispatch({ type: DELETE_ALL_FROM_CART });
  };
};

// get stock of cart item
export const cartItemStock = (item, color, size) => {
  if (item.stock) {
    return item.stock;
  } else {
    return item.variation
      .filter(single => single.color === color)[0]
      .size.filter(single => single.name === size)[0].stock;
  }
};
