import axios from "axios";
import config from '../configKiotViet'
const API_URL = config.API;
class AuthService {
  getToken() {
    return axios.get(API_URL+"KiotvietAPI/Token").then((response) => {
      return response;
      console.log(response)
    });;
  }
}

export default new AuthService();