import axios from 'axios';
import config from '../configKiotViet/index';
var reduxData = JSON.parse(localStorage.getItem('redux_localstorage_simple'));
var token = ""
if (reduxData && reduxData.kiotViet.token != "") {
    token = reduxData.kiotViet.token
}

const instance = axios.create({
    baseURL: config.API
});
instance.interceptors.request.use(
    (config) => {
        // Modify the data before sending the request
        if (config.method === 'post') {
            // Assuming you want to add keydata and Retailer to the request data
            config.data = {
                ...config.data,
                keydata: token,
                Retailer: 'Picie'
            };
        }
        return config;
    },
    (error) => {
        // Do something with request error
        return Promise.reject(error);
    }
);
export default instance;